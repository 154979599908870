import { useEffect, useMemo, useState } from "react";

enum Breakpoints {
  SM = "480px",
  MD = "1024px",
  LG = "1440px"
}

enum MatchedBreakpoint {
  SM_AND_UP,
  MD_AND_UP,
  LG_AND_UP
}

interface BreakpointsMatchHookOutput {
  smAndUp: boolean;
  mdAndUp: boolean;
  lgAndUp: boolean;
}

export const useBreakpointsMatch = (): BreakpointsMatchHookOutput => {
  const [matchedBreakpoint, setMatchedBreakpoint] = useState<MatchedBreakpoint | null>(null);

  const lgAndUp = useMemo(() => matchedBreakpoint === MatchedBreakpoint.LG_AND_UP, [matchedBreakpoint]);
  const mdAndUp = useMemo(
    () => matchedBreakpoint === MatchedBreakpoint.MD_AND_UP || matchedBreakpoint === MatchedBreakpoint.LG_AND_UP,
    [matchedBreakpoint]
  );
  const smAndUp = useMemo(
    () =>
      matchedBreakpoint === MatchedBreakpoint.SM_AND_UP ||
      matchedBreakpoint === MatchedBreakpoint.MD_AND_UP ||
      matchedBreakpoint === MatchedBreakpoint.LG_AND_UP,
    [matchedBreakpoint]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.matchMedia(`(min-width: ${Breakpoints.LG})`).matches) {
        setMatchedBreakpoint(MatchedBreakpoint.LG_AND_UP);
      } else if (window.matchMedia(`(min-width: ${Breakpoints.MD})`).matches) {
        setMatchedBreakpoint(MatchedBreakpoint.MD_AND_UP);
      } else if (window.matchMedia(`(min-width: ${Breakpoints.SM})`).matches) {
        setMatchedBreakpoint(MatchedBreakpoint.SM_AND_UP);
      }
    }
  }, []);

  return {
    smAndUp,
    mdAndUp,
    lgAndUp
  };
};
