import styles from "./am_sponsor_banner.module.scss";
import Image from "next/image";

interface AMSponsorBannerProps {
  bgImage?: string;
  sponsorLogo?: string;
  title: string;
  claim: string;
}

export const AMSponsorBanner = ({ bgImage, sponsorLogo, title, claim }: AMSponsorBannerProps) => {
  return (
    <div className={styles["am-sponsor-banner"]}>
      {bgImage ? (
        <Image src={bgImage} alt="sponsor-banner" layout="fill" objectFit="cover" />
      ) : (
        <div className={styles["am-sponsor-banner__empty-bg"]} />
      )}
      <div className={styles["am-sponsor-banner__content"]}>
        <h1 className="h1">{title}</h1>
        {sponsorLogo && (
          <div className={styles["am-sponsor-banner__content__brand"]}>
            <p className="body3">{claim}</p>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={sponsorLogo} alt="sponsor-logo" />
          </div>
        )}
      </div>
    </div>
  );
};
