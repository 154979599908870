import { AMFormikProps, FormikInputProps } from "@/src/common/ui/view_models/formik";
import { FieldInputProps, FieldMetaProps } from "formik/dist/types";
import { FormikHandlers, useField } from "formik";
import styles from "./am_checkbox.module.scss";
import { Check } from "@/src/common/ui/icons";
import { ChangeEvent, forwardRef, KeyboardEvent, ReactNode, useRef } from "react";
import { useForceUpdate } from "@/src/common/ui/hooks/render";

export interface AMCheckboxProps {
  id: string;
  label: string | ReactNode;
  name?: string;
  value?: string;
  onChange?: FormikHandlers["handleChange"];
  formik?: AMFormikProps;
  className?: string;
  title?: string;
}

export const AMCheckbox =
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLLabelElement, AMCheckboxProps>(({ id, name, title, value, formik, onChange, label }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const forceUpdate = useForceUpdate();
    let field: FieldInputProps<any>;
    let meta: FieldMetaProps<any>;
    if (formik) {
      field = formik.field;
      meta = formik.meta;
    } else {
      const emptyHandler = () => {};
      field = {
        onBlur: emptyHandler,
        value: value || undefined,
        onChange: onChange || emptyHandler,
        name: name || id || ""
      };
      meta = { initialTouched: false, initialValue: undefined, value: undefined, touched: false };
    }

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
      if (!formik) {
        forceUpdate();
      }
    };

    const handleKeypress = (e: KeyboardEvent<HTMLLabelElement>) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        innerRef.current?.click();
      }
    };

    return (
      <label ref={ref} className={styles["am-checkbox"]} htmlFor={id} tabIndex={0} onKeyPress={handleKeypress} title={title}>
        <input ref={innerRef} id={id} name={field.name} type="checkbox" onChange={_onChange} value={field.value} checked={field.checked} />
        <div className={styles["am-checkbox__box"]}>{(field.checked || innerRef.current?.checked) && <Check />}</div>
        <span>{label}</span>
      </label>
    );
  });

type AMCheckboxFormikProps = FormikInputProps & Omit<AMCheckboxProps, "formik">;

export const AMCheckboxFormik = ({ id, label, name, value, onChange, className, title }: AMCheckboxFormikProps) => {
  const [field, meta] = useField({ name, value, type: "checkbox" });
  if (onChange) field.onChange = onChange;
  return <AMCheckbox id={id} formik={{ field, meta }} label={label} className={className} title={title} />;
};
