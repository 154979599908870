import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./am_products_slider.module.scss";
import cs from "classnames";
import { ArrowDown } from "@/src/common/ui/icons";
import { AMIconButton } from "@/src/common/ui/components/am_icon_button/am_icon_button";
import { MouseEventHandler, useRef, useState } from "react";
import { Swiper as SwiperClass } from "swiper/types";
import { AMButton } from "@/src/common/ui/components/am_button/am_button";
import { IProduct } from "@/src/products/domain/interfaces/product";
import { useTranslation } from "next-i18next";
import { AMProductCard } from "@/src/products/ui/components/am_product_card/am_product_card";
import { AMLoader } from "@/src/common/ui/components/am_loader/am_loader";
import { getSwiperPosition, SwiperPosition } from "@/src/common/ui/utils/swiper";
import { useBreakpointsMatch } from "@/src/common/ui/hooks/breakpoints_match";

interface AMProductsSliderProps {
  sliderClassName?: string;
  className?: string;
  title?: string;
  products?: Array<IProduct>;
  loading?: boolean;
  onShowMore?: MouseEventHandler;
}

export const AMProductsSlider = ({ className, sliderClassName, title, products = [], loading, onShowMore }: AMProductsSliderProps) => {
  const { t } = useTranslation("product");
  const swiperRef = useRef<SwiperClass>();
  const [swiperPosition, setSwiperPosition] = useState<SwiperPosition>("beginning");
  const { mdAndUp } = useBreakpointsMatch();

  return (
    <div className={cs(styles["am-products-slider"], className)}>
      {title && <h4 className="h4--bold">{title}</h4>}
      {loading ? (
        <div className={styles["am-products-slider__loader"]}>
          <AMLoader />
        </div>
      ) : (
        <>
          <AMIconButton
            className={cs(
              styles["am-products-slider-swiper__control-left"],
              swiperPosition === "beginning" && styles["am-products-slider-swiper__control-left--hidden"]
            )}
            icon={<ArrowDown />}
            amStyles="am-round"
            onClick={() => swiperRef.current?.slidePrev()}
          />
          <AMIconButton
            className={cs(
              styles["am-products-slider-swiper__control-right"],
              swiperPosition === "end" && styles["am-products-slider-swiper__control-right--hidden"]
            )}
            icon={<ArrowDown />}
            amStyles="am-round"
            onClick={() => swiperRef.current?.slideNext()}
          />
          {products && products.length && (
            <Swiper
              className={cs(styles["am-products-slider-swiper"], sliderClassName)}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onSlideChange={(swiper) => {
                setSwiperPosition(getSwiperPosition(swiper));
              }}
              onReachEnd={() => {
                setSwiperPosition("end");
              }}
              slidesPerView="auto"
              spaceBetween={mdAndUp ? 24 : 16}
            >
              {products.map((product) => (
                <SwiperSlide key={product.id}>
                  <AMProductCard
                    asin={product.asin}
                    basePriceFormatted={product.basePrice?.priceFormatted}
                    hasPrime={product.hasPrime}
                    image={product.images.primary.large.url}
                    name={product.title}
                    family={product.familyByColors}
                    priceFormatted={product.price.priceFormatted}
                    rating={product.ratingsCount}
                    staRating={product.ratingsStars}
                    style={"am-slider"}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          {onShowMore && (
            <AMButton className={styles["am-products-slider__see-more"]} onClick={onShowMore}>
              {t("slider.seeMoreBtn")}
            </AMButton>
          )}
        </>
      )}
    </div>
  );
};
