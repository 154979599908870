import { Swiper as SwiperClass } from "swiper/types";

export type SwiperPosition = "beginning" | "end" | null;

export const getSwiperPosition = (swiper: SwiperClass): SwiperPosition => {
  if (swiper.isBeginning) {
    return "beginning";
  } else if (swiper.isEnd) {
    return "end";
  } else {
    return null;
  }
};
