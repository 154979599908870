import styles from "./am_stars.module.scss";
import { StarFull, StarEmpty, StarHalf } from "@/src/common/ui/icons";

interface AMStarsProps {
  stars: number;
  starsToPaint?: number;
}

export const AMStars = ({ stars, starsToPaint = 5 }: AMStarsProps) => {
  const roundStars = Math.round(stars * 2) / 2;

  const starsLoop = () => {
    const starsArray = [];
    for (let index = 0; index < starsToPaint; index++) {
      roundStars - index >= 1 &&
        starsArray.push(
          <span className={styles.stars} key={index}>
            {<StarFull />}
          </span>
        );
      roundStars - index === 0.5 &&
        starsArray.push(
          <span className={styles.stars} key={index}>
            {<StarHalf />}
          </span>
        );
      roundStars - index <= 0 &&
        starsArray.push(
          <span className={styles.stars} key={index}>
            {<StarEmpty />}
          </span>
        );
    }
    return starsArray;
  };

  return <div className={styles.container}>{starsLoop()}</div>;
};
