import { useAppDispatch } from "@/src/common/ui/store";
import { useRouter } from "next/router";
import { BagType } from "@/src/products/domain/models/bag";
import { setFilters } from "@/src/products/ui/store/product_filters.slice";

export const useSeeMoreProduct = () => {
  const router = useRouter();

  const navigateToBagType = (bagType?: BagType) => {
    switch (bagType) {
      case BagType.CABIN:
        router.push("/cabin-bag");
        break;
      case BagType.EXTRA:
        router.push("/checked-in-bag");
        break;
      case BagType.SMALL:
        router.push("/underseat-bag");
        break;
      default:
        router.push("/filter");
        break;
    }
  };

  return { navigateToBagType };
};
