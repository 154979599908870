import styles from "./am_flight_use_button.module.scss";
import { AMIconButton } from "../am_icon_button/am_icon_button";
import type { MouseEventHandler } from "react";

interface AMFlightUseButtonProps {
  onClick: MouseEventHandler;
  icon: React.ReactNode;
  amLabel: string;
}

export const AMFlightUseButton = ({ amLabel, icon, onClick }: AMFlightUseButtonProps) => {
  return (
    <div className={styles["am-icon"]} onClick={onClick}>
      <AMIconButton icon={icon} amStyles="am-flight-use" />
      <span className={styles["am-label"]}>{amLabel}</span>
    </div>
  );
};
