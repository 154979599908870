import React, { useState, useRef, KeyboardEvent, Fragment, useMemo, ChangeEvent } from "react";
import { ControlledMenu, FocusableItem, MenuDivider, MenuGroup, MenuItem, MenuState } from "@szhsin/react-menu";
import styles from "./am_select_search.module.scss";
import { AMInput } from "@/src/common/ui/components/am_input/am_input";
import { AMCheckbox } from "@/src/common/ui/components/am_checkbox/am_checkbox";
import cs from "classnames";
import { AMSelectSearchOption } from "@/src/common/ui/view_models/select_search_option";
import { AMButton } from "@/src/common/ui/components/am_button/am_button";
import { normalizeAndLowerCase } from "@/src/common/utils/string";
import { useClickOutside } from "@/src/common/ui/hooks/mouse";
import { ArrowDown } from "@/src/common/ui/icons";

export interface AMSelectSearchProps {
  options: Array<AMSelectSearchOption>;
  onChange?: (options: Array<AMSelectSearchOption>) => void;
  searchButtonLabel: string;
  placeholder: string;
}

// TODO implement non multi select search in case of necessary avoid creating a new component
export const AMSelectSearch = ({ options, searchButtonLabel, onChange, placeholder }: AMSelectSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [menuState, setMenuState] = useState<MenuState>("closed");
  const [selected, setSelected] = useState<Array<AMSelectSearchOption>>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const searchedOptions = useMemo<Array<AMSelectSearchOption>>(
    () => options.filter((op) => normalizeAndLowerCase(op.label).includes(normalizeAndLowerCase(searchValue))),
    [options, searchValue]
  );

  useClickOutside(wrapperRef, () => {
    if (menuState === "open") {
      setMenuState("closed");
    }
  });

  const toggleSelected = (option: AMSelectSearchOption) => {
    if (selected.some((op) => op.id === option.id)) {
      setSelected(selected.filter((op) => op.id !== option.id));
    } else {
      setSelected([...selected, option]);
    }
  };

  return (
    <div ref={wrapperRef} className={styles["am-select-search"]}>
      <AMInput
        ref={inputRef}
        id="am-select-search"
        onFocus={() => setMenuState("open")}
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
        icon={<ArrowDown />}
      />

      <ControlledMenu
        offsetY={8}
        className={styles["am-select-search__menu-container"]}
        menuClassName={styles["am-select-search__menu"]}
        state={menuState}
        overflow="hidden"
        anchorRef={inputRef}
        captureFocus={false}
        onClose={() => setMenuState("closed")}
      >
        <MenuGroup className={styles["am-select-search__menu-group"]}>
          {searchedOptions.map((option, idx) => (
            <Fragment key={option.id}>
              <FocusableItem
                key={`${option.id}_item`}
                className={({ hover }) => cs(styles["am-select-search__menu__item"], hover && styles["am-select-search__menu__item--hover"])}
              >
                {({ ref }) => (
                  <AMCheckbox
                    ref={ref}
                    id={option.id}
                    label={option.label}
                    onChange={() => {
                      toggleSelected(option);
                    }}
                  />
                )}
              </FocusableItem>
              <MenuDivider key={`${option.id}_divider`} className={styles["am-select-search__menu__item-divider"]} />
            </Fragment>
          ))}
        </MenuGroup>
        <MenuItem
          className={({ hover }) =>
            cs(styles["am-select-search__menu__search-button"], hover && styles["am-select-search__menu__search-button--hover"])
          }
        >
          <AMButton amStyles="am-orange" onClick={() => onChange?.(selected)}>
            {searchButtonLabel}
          </AMButton>
        </MenuItem>
      </ControlledMenu>
    </div>
  );
};
