import { AMStars } from "@/src/common/ui/components/am_stars/am_stars";
import styles from "./am_product_card.module.scss";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import cs from "classnames";
import AMColorButton from "@/src/common/ui/components/am_color_button/am_color_button";
import Link from "next/link";
import { FamilyProductColor } from "@/src/products/domain/models/family_product_color";

// Find a way to decouple link component from card and also be able to use it externally
// Maybe through a render prop

interface AMProductCardProps {
  asin: string;
  basePriceFormatted: string;
  hasPrime: boolean;
  image: string;
  name: string;
  family: Array<FamilyProductColor>;
  priceFormatted: string;
  rating: number;
  staRating: number;
  style: "am-slider" | "am-grid" | "am-detail";
}

export const AMProductCard = ({
  asin,
  basePriceFormatted,
  hasPrime,
  image,
  name,
  family,
  priceFormatted,
  rating,
  staRating,
  style
}: AMProductCardProps) => {
  const { t } = useTranslation("product", { keyPrefix: "card" });

  interface IColors {
    color: string;
    asin: string;
  }

  const familyVariations = () => {
    return family?.map((famProdColor, index) => {
      if (index < 4) {
        return (
          <Link key={index} passHref href={`/product/${famProdColor.products[0].asin}`}>
            <AMColorButton colorImgUrl={famProdColor.products[0].images?.primary?.smallUrl} />
          </Link>
        );
      }
      if (index === 4) {
        return (
          <Link key={index} href={`/product/${famProdColor.products[0].asin}`}>
            <a>{t("more")}</a>
          </Link>
        );
      }
      return false;
    });
  };

  return (
    <div className={cs(styles["am-product"], styles[style])}>
      <figure>
        <Link href={`/product/${asin}`}>
          <a>
            <Image src={image} alt="image" layout="fill" objectFit="contain" />
          </a>
        </Link>
      </figure>
      <div className={styles["am-product__family"]}>{familyVariations()}</div>
      <Link href={`/product/${asin}`}>
        <a className={styles["am-product__money"]}>
          <span className={"body3"}>{t("fromAmount")}</span>
          <span className={"h4--bold"}>{priceFormatted}</span>
          {basePriceFormatted && <span className={styles["am-product__money--baseprice"]}>{basePriceFormatted}</span>}
        </a>
      </Link>
      <Link href={`/product/${asin}`}>
        <a className={styles["am-product__prime"]}>
          {hasPrime && (
            <figure>
              <Image src="/assets/images/prime_logo.svg" priority layout="fill" alt="logo" />
            </figure>
          )}
        </a>
      </Link>
      <div className={cs("body2", styles["am-product__description"], styles[`${style}__description`])}>
        <Link href={`/product/${asin}`}>
          <a>
            <span>{name}</span>
          </a>
        </Link>
      </div>
      <Link href={`/product/${asin}`}>
        <a className={styles["am-product__stars"]}>
          <AMStars stars={staRating} />
          <span className={"body3"}>{rating}</span>
        </a>
      </Link>
    </div>
  );
};
