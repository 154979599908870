import { Expose } from "class-transformer";

export class Sponsor {
  @Expose({ name: "backgroundImage" })
  coverPhoto!: string;
  @Expose({ name: "mobileBackgroundImage" })
  mobileCoverPhoto!: string;
  logo!: string;
  name!: string;
}
