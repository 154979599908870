import type { GetServerSideProps } from "next";
import styles from "./index.module.scss";
import { ReactElement, useEffect, useMemo } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { CabinBag, CheckedInBag, UnderSeatBag } from "@/src/common/ui/icons";
import { AMBaseLayout } from "@/src/common/ui/components/am_base_layout/am_base_layout";
import { AMSelectSearch } from "@/src/common/ui/components/am_select_search/am_select_search";
import { locator } from "@/ioc/index";
import { TYPES } from "@/ioc/types";
import { classToPlain, deserializeArray, plainToClass } from "class-transformer";
import { Airline } from "@/src/airlines/domain/models/airline";
import { AMSelectSearchOption } from "@/src/common/ui/view_models/select_search_option";
import { Sponsor } from "@/src/common/domain/models/sponsor";
import { AMSponsorBanner } from "@/src/common/ui/components/am_sponsor_banner/am_sponsor_banner";
import { AMFlightUseButton } from "../common/ui/components/am_flight_use_button/am_flight_use_button";
import type { GetHomeDataUseCase } from "@/src/common/domain/use_cases/get_home_data_use_case";
import { AMProductsSlider } from "@/src/products/ui/components/am_products_slider/am_products_slider";
import { Bag, BagType } from "@/src/products/domain/models/bag";
import { toSelectSearchOption } from "@/src/airlines/ui/transformers/to_select_search_option";
import { useAppDispatch } from "@/src/common/ui/store";
import { useRouter } from "next/router";
import { setFilters } from "@/src/products/ui/store/product_filters.slice";
import { useBreakpointsMatch } from "@/src/common/ui/hooks/breakpoints_match";
import { useSeeMoreProduct } from "@/src/products/ui/hooks/filter";
import TagManager from "react-gtm-module";
import type { GetMostLovedUseCase } from "@/src/products/domain/use_cases/get_most_loved_use_case";

interface HomePageProps {
  airlines: Array<string>;
  sponsor: string | null;
  mostLoved: {
    cabin: string;
    extra: string;
    small: string;
  };
}

export default function HomePage({ airlines = [], sponsor = null, mostLoved }: HomePageProps) {
  const { t } = useTranslation(["home", "product"]);
  const { mdAndUp } = useBreakpointsMatch();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { navigateToBagType } = useSeeMoreProduct();

  const sponsorDomain = useMemo<Sponsor | null>(() => (sponsor ? plainToClass(Sponsor, JSON.parse(sponsor)) : null), [sponsor]);
  const airlinesOption = useMemo<Array<AMSelectSearchOption>>(
    () =>
      airlines.map((airlineJson) => {
        const airline = plainToClass(Airline, JSON.parse(airlineJson));
        return toSelectSearchOption(airline);
      }),
    [airlines]
  );

  const cabinBags = useMemo<Array<Bag>>(() => deserializeArray(Bag, mostLoved.cabin), [mostLoved.cabin]);
  const extraBags = useMemo<Array<Bag>>(() => deserializeArray(Bag, mostLoved.extra), [mostLoved.extra]);
  const smallBags = useMemo<Array<Bag>>(() => deserializeArray(Bag, mostLoved.small), [mostLoved.small]);

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: "gtm-page view" } });
  }, []);

  const setAirlinesAndNavigate = (airlines: Array<string>, airlabels: Array<string>) => {
    TagManager.dataLayer({ dataLayer: { airlabels: airlabels.toString(), event: "gtm-click-search-airline" } });
    dispatch(setFilters({ airlines }));
    router.push("/filter");
  };

  const handleNavigateToBagType = (bagType: BagType, event: String) => {
    TagManager.dataLayer({ dataLayer: { event: event } });
    navigateToBagType(bagType);
  };

  return (
    <div className={styles["home"]}>
      <div className={styles["home__banner"]}>
        <div className={styles["home__banner__wrapper"]}>
          <AMSponsorBanner
            sponsorLogo={sponsorDomain?.logo}
            bgImage={mdAndUp ? sponsorDomain?.coverPhoto : sponsorDomain?.mobileCoverPhoto}
            claim={t("home:sponsor.claim")}
            title={t("home:sponsor.title")}
          />
        </div>
      </div>
      <div className={styles["home__search-by"]}>
        <div className={styles["home__search-by__wrapper"]}>
          <div className={styles["home__search-by-flight-use"]}>
            <p className="body1--bold">{t("home:searchBy.flightUse.title")}</p>
            <div>
              <AMFlightUseButton
                onClick={() => handleNavigateToBagType(BagType.SMALL, "gtm-click-use-underseat")}
                icon={<UnderSeatBag />}
                amLabel={t("home:searchBy.flightUse.underSeatBtn")}
              />
              <AMFlightUseButton
                onClick={() => handleNavigateToBagType(BagType.CABIN, "gtm-click-use-cabin")}
                icon={<CabinBag />}
                amLabel={t("home:searchBy.flightUse.cabinBtn")}
              />
              <AMFlightUseButton
                onClick={() => handleNavigateToBagType(BagType.EXTRA, "gtm-click-use-checked-in")}
                icon={<CheckedInBag />}
                amLabel={t("home:searchBy.flightUse.checkInBtn")}
              />
            </div>
          </div>
          <div className={styles["home__search-by-airlines"]}>
            <p className="body1--bold">{t("home:searchBy.airlines.title")}</p>
            <AMSelectSearch
              placeholder={t("home:searchBy.airlines.placeholder")}
              searchButtonLabel={t("home:searchBy.airlines.searchBtn")}
              // onChange={(options) => setAirlinesAndNavigate(options)}
              onChange={(options) =>
                setAirlinesAndNavigate(
                  options.map((op) => op.id),
                  options.map((op) => op.label)
                )
              }
              options={airlinesOption}
            />
          </div>
        </div>
      </div>
      <div className={styles["home__most-loved"]}>
        <div className={styles["home__most-loved__wrapper"]}>
          <h3 className="h3">{t("home:mostLoved.title")}</h3>
          <p className="body3">{t("home:mostLoved.description")}</p>
          <AMProductsSlider
            className={styles["home__most-loved__slider"]}
            sliderClassName={styles["home__most-loved__slider-swiper"]}
            title={t("product:slider.cabinTitle")}
            products={cabinBags}
            onShowMore={() => navigateToBagType(BagType.CABIN)}
          />
          <AMProductsSlider
            className={styles["home__most-loved__slider"]}
            sliderClassName={styles["home__most-loved__slider-swiper"]}
            title={t("product:slider.underSeatTitle")}
            products={smallBags}
            onShowMore={() => navigateToBagType(BagType.SMALL)}
          />
          <AMProductsSlider
            className={styles["home__most-loved__slider"]}
            sliderClassName={styles["home__most-loved__slider-swiper"]}
            title={t("product:slider.checkInTitle")}
            products={extraBags}
            onShowMore={() => navigateToBagType(BagType.EXTRA)}
          />
        </div>
      </div>
    </div>
  );
}

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <AMBaseLayout>{page}</AMBaseLayout>;
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const [getHomeDataUseCase, getMostLovedUseCase] = await Promise.all([
    locator.get<() => Promise<GetHomeDataUseCase>>(TYPES.GetHomeDataUseCase)(),
    locator.get<() => Promise<GetMostLovedUseCase>>(TYPES.GetMostLovedUseCase)()
  ]);
  const [{ airlines, sponsor }, { cabin, extra, small }] = await Promise.all([getHomeDataUseCase.execute(), getMostLovedUseCase.execute()]);
  return {
    props: {
      airlines: airlines.map((airline) => JSON.stringify({ ...classToPlain(airline) })),
      sponsor: sponsor ? JSON.stringify(classToPlain(sponsor)) : null,
      mostLoved: {
        cabin: JSON.stringify(classToPlain(cabin)),
        extra: JSON.stringify(classToPlain(extra)),
        small: JSON.stringify(classToPlain(small))
      },
      ...(await serverSideTranslations(locale || "en"))
    }
  };
};
