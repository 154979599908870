import styles from "./am_color_button.module.scss";
import { forwardRef, Ref } from "react";
import Image from "next/image";

interface Props {
  colorImgUrl: string | undefined;
  onClick?: () => void;
  href?: string;
}

// eslint-disable-next-line react/display-name
const AMColorButton = forwardRef<HTMLAnchorElement, Props>(({ colorImgUrl, onClick, href }, ref) => {
  return (
    <a ref={ref} href={href} onClick={onClick} className={styles["cb"]}>
      <figure>{colorImgUrl && <Image src={colorImgUrl} alt="color" layout="fill" objectFit="cover" />}</figure>
    </a>
  );
});

export default AMColorButton;
